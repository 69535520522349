.bootstrap-datetimepicker-widget {
    width: 240px;
    padding: 10px;
    .datepicker {
        .datepicker-days {
            table {
                width: 100%;
            }
        }
    }
}