//
// Widget 1
//

.analytics-dashboard{
      display: grid;
      grid-template-columns: 1fr 1fr;
  column-gap: 20px;

      & > * {
        grid-column: span 1;
        &:nth-child(2){
          text-align: right;
        }
        &:nth-child(1), &:nth-child(2){
          margin-bottom: 20px;
        }
      }
    }

.nested-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;
  column-gap: 20px;
  & > * {
    grid-column: span 1;
    &.double-width{
      grid-column: span 2;
      &.feedback{
        margin-bottom: 20px;
        .card {
          box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
          border-radius: 4px;
        }

      }
    }
  }
}

.teszt {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
div#gapi-view-selector-container {
  display: flex;
  flex-wrap: nowrap;
}
//.chartMargin {
//  margin: 10px;
//}
//
//.googleChart {
//  max-width: 50%;
//  flex: 0 0 50%;
//  margin-right: 20px;
//}

.kt-widget1 {
  padding: kt-get($kt-portlet, space, desktop);

  &.kt-widget1--fit {
    padding: 0;
  }

  &.kt-widget1--paddingless {
    padding: 0;
  }


  .kt-widget1__item {
    padding: 1.1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      padding-bottom: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    .kt-widget1__info {
      .kt-widget1__title {
        font-size: 1.1rem;
        font-weight: 500;
        color: kt-base-color(label, 3);
      }

      .kt-widget1__desc {
        font-size: 1rem;
        font-weight: normal;
        color: kt-base-color(label, 2);
      }
    }

    .kt-widget1__number {
      font-size: 1.4rem;
      font-weight: 600;
      color: kt-base-color(label, 2);
    }

    &:last-child {
      border-bottom: 0;
    }

    border-bottom: 0.07rem dashed kt-base-color(grey, 2);
  }
}

@include kt-tablet-and-mobile {
  .kt-widget1 {
    padding: kt-get($kt-portlet, space, mobile);
  }
}

@media (min-width: 1025px) {
 .kt-portlet.kt-portlet--height-fluid {
  height: unset;
 } }
