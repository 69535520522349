@mixin button-spacing($space-value) {
  .btn {
    margin-left: $space-value;
    &:nth-of-type(1) {
      margin-left: unset;
    }
  }
}

.kt-portlet__foot {
  display: flex;
  justify-content: flex-start;
  @include button-spacing(5px);
}

.kt-portlet__head-toolbar {
  @include button-spacing(5px);
}
