//
// Form
//
@import "../widgets/general/date-picker";


.kt-form {
    .kt-form__group {
        &.kt-form__group--inline {
            display: table;
            padding: 0;

            > .kt-form__label {
                display: table-cell;
                vertical-align: middle;
                margin: 0;
                padding-right: 1rem;

                &.kt-form__label-no-wrap {
                    white-space: nowrap;
                }

                > label {
                    margin: 0;
                }
            }

            > .kt-form__control {
                display: table-cell;
                vertical-align: middle;
                margin: 0;
                width: 100%;
            }
        }
    }

    // Action bar
    .kt-form__actions {
        &.kt-form__actions--right {
            text-align: right;
        }

        &.kt-form__actions--center {
            text-align: center;
        }
    }

    &.kt-form--center {
        width: 60%;
        margin: 0 auto;
    }
}

// Validation feedback
.is-valid {
    .valid-feedback {
        display: inline-block;
    }
}

.is-invalid {
    .invalid-feedback {
        display: inline-block;
    }
}

.drop-zone {
    background-color: rgba($gray-100, 0.5);
    border: 1px dashed rgba($gray-600, 0.5);
    border-radius: 5px;
    min-height: 400px;
    max-height: 500px;
    width: 100%;
	display: table;
	cursor: pointer;

	&:focus, &:hover {
		border: 1px dashed $gray-600;
	}

    .container {
		height: 100%;
		display: table-cell;
		width: 100%;
		vertical-align: middle;
		text-align: center;

        .upload-container {
            display: grid;
            grid-template-columns: auto;
            grid-gap: 10px;
            img {
                max-width: 100%;
                max-height: 320px;
            }

            .la {
                &.la-cloud-upload {
                    font-size: 36px;
                }
            }
            &.preview {
                grid-template-columns: auto auto auto;
            }
        }
    }
}

@include kt-desktop {
    .kt-form {
        &.kt-form--label-right {
            .form-group {
                label:not(.kt-checkbox):not(.kt-radio):not(.kt-option) {
                    text-align: right;
                }
            }
        }
    }
}