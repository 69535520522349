// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
//@import "../node_modules/bootstrap/dist/css/bootstrap.css";
// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";
// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

@import "./assets/style/portlet.scss";

.Toastify__toast-container--top-right,
.Toastify__toast-container--top-left,
.Toastify__toast-container--top-center {
  padding-top: 100px;

  .Toastify__toast {
    border-radius: 15px;

    &.Toastify__toast--default {
      background: $white;
      color: $gray-600 !important;

      .Toastify__progress-bar--default {
        background: $blue;
      }
    }

    &.Toastify__toast--success {
      background: $green;
      color: $white !important;

      .Toastify__toast-body {
        a {
          color: $white !important;
        }
      }
    }

    &.Toastify__toast--info {
      background: $blue;
      color: $white !important;

      .Toastify__toast-body {
        a {
          color: $white !important;
        }
      }
    }

    &.Toastify__toast--warning {
      background: $yellow;
      color: $gray-600 !important;

      .Toastify__toast-body {
        a {
          color: $gray-600 !important;
        }
      }
    }

    &.Toastify__toast--error {
      background: $red;
      color: $white !important;

      .Toastify__toast-body {
        a {
          color: $white !important;
        }
      }
    }

    .Toastify__toast-body {
      overflow-wrap: anywhere;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.toast-image,
.toast-text {
  display: inline-block;
}

.toast-image {
  max-width: 60px;
  border-radius: 50%;
}

.toast-text {
  max-width: calc(100% - 60px);
  overflow-wrap: anywhere;
  padding-left: 10px;
}

.dropdown-badge {

  &.badge {
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 9px;
  }
}

.MuiTableRow-root:hover {
  background: #dedede !important
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft {
  max-width: 30rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.kt-notification {
  .notification-item {
    width: calc(100% + 120px);
    position: relative;
    transition: all 1s linear;
    left: 0;

    .options {
      display: flex;
      flex-grow: 1;
      max-width: 120px;

      i {
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &:hover {
      left: -120px;
    }

    &:after {
      display: none;
    }
  }
}

.kt-splash-screen {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $kt-content-bg-color;
}

.btn {
  &:disabled {
    cursor: not-allowed;
  }
}

#overlay {
  z-index: 5000!important;
}
